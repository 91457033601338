import { saveAs } from 'file-saver';
import { Buffer } from 'buffer';
import {
	AlignmentType,
	Document,
	Footer,
	Header,
	HorizontalPositionAlign,
	HorizontalPositionRelativeFrom,
	ImageRun,
	Packer,
	Paragraph,
	SectionType,
	ShadingType,
	Table,
	TableCell,
	TableRow,
	TextRun,
	TextWrappingSide,
	TextWrappingType,
	VerticalPositionAlign,
	VerticalPositionRelativeFrom,
	WidthType,
	convertInchesToTwip,
} from 'docx';
import imageToBase64 from 'image-to-base64/browser';

const elysiumNS = async (staff, logo, user) => {
	const basicLifeSupport = staff.staff.trainings.find((training) => training.name == 'Basic Life Support (HCW)');
	const immediateLifeSupport = staff.staff.trainings.find(
		(training) => training.name == 'Immediate Life support (RMN)'
	);
	const manualHandling = staff.staff.trainings.find((training) => training.name == 'Manual Handling');
	const healthSafety = staff.staff.trainings.find((training) => training.name == 'Health and Safety');
	const breakAway = staff.staff.trainings.find((training) => training.name == 'Breakaway');
	const oliver = staff.staff.trainings.find(
		(training) => training.name == 'Oliver McGowan LD & Autism' || training.name == 'Oliver McGowan LD and Autism'
	);
	const violenceAggression = staff.staff.trainings.find(
		(training) => training.name == '(GSA) Management of violence and aggression'
	);
	const infectionControl = staff.staff.trainings.find((training) => training.name == 'Infection Control');
	const safeguard = staff.staff.trainings.find(
		(training) => training.name == 'Safeguarding (adults and children Level 3)'
	);
	const dataProtection = staff.staff.trainings.find((training) => training.name == 'GDPR');
	const treatmentPlanning = staff.staff.trainings.find(
		(training) => training.name == 'Formulation and Treatment Planning'
	);
	const mentalAct = staff.staff.trainings.find((training) => training.name == 'Mental Health Act');
	const competence = staff.staff.trainings.find((training) => training.name == 'Competence and Capacity');
	const informationGovernance = staff.staff.trainings.find(
		(training) => training.name == 'Information Governance' || training.name == 'GDPR'
	);

	const prevent = staff.staff.trainings.find((training) => training.name == 'Prevent Radicalisation');
	const behaviour = staff.staff.trainings.find((training) => training.name == 'Behaviour Support');
	const youngPeople = staff.staff.trainings.find(
		(training) => training.name == 'Legal Framework for Working with Young People'
	);
	const clinicalRiskAssessment = staff.staff.trainings.find(
		(training) => training.name == 'Clinical Risk Assessment, Formulation and Management'
	);

	const gdpr = staff.staff.trainings.find((training) => training.name == 'GDPR');

	const conflict = staff.staff.trainings.find(
		(training) => training.name == 'Conflict Management and Complaints Handling'
	);
	const depravation = staff.staff.trainings.find((training) => training.name == 'Deprivation of Liberties Safeguards');
	const foodHygiene = staff.staff.trainings.find((training) => training.name == 'Food and Hand Hygiene');
	const equality = staff.staff.trainings.find((training) => training.name == 'Equality and Diversity');
	const loneworker = staff.staff.trainings.find((training) => training.name == 'Lone Worker');
	const coshh = staff.staff.trainings.find((training) => training.name == 'Coshh');
	const personCentered = staff.staff.trainings.find((training) => training.name == 'Person Centered Care');
	const riddor = staff.staff.trainings.find((training) => training.name == 'Riddor');
	const knife = staff.staff.trainings.find((training) => training.name == 'Ligature Knife Training');
	const camhs = staff.staff.trainings.find(
		(training) => training.name == 'Health Care Support Worker certificate and mental health inpatient settings (CAMHS)'
	);
	const observations = staff.staff.trainings.find((training) => training.name == 'Observations');
	const moving = staff.staff.trainings.find(
		(training) => training.name == 'Moving & Handling' || training.name == 'Moving and Handling'
	);
	const dysphagia = staff.staff.trainings.find((training) => training.name == 'Dysphagia');
	const selfharm = staff.staff.trainings.find((training) => training.name == 'Working with those who self-harm');
	const eatingDisorder = staff.staff.trainings.find((training) => training.name == 'Understanding Eating Disorders');
	const riskAssessment = staff.staff.trainings.find((training) => training.name == 'Risk Assessment');
	const medicalManagement = staff.staff.trainings.find((training) => training.name == 'Medication Management');
	const news = staff.staff.trainings.find((training) => training.name == 'News 2');
	const fire = staff.staff.trainings.find((training) => training.name == 'Fire Awareness');

	const righttowork = staff.staff.documents.find((document) => document.doctype == 'Right to Work');
	const companyId = staff.staff.documents.find((document) => document.doctype == 'Company ID');

	const references = staff.staff.references.filter((reference) => reference.terms == 'accepted');

	const options = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};

	function trainingRow(name, validity = '1 Year', training) {
		return new TableRow({
			children: [
				new TableCell({
					width: {
						size: 50,
						type: WidthType.PERCENTAGE,
					},
					margins: { top: 50, bottom: 50, left: 50 },

					children: [
						new Paragraph({
							children: [
								new TextRun({
									text: name,

									font: 'Calibri',
									size: 18,
								}),
							],
						}),
					],
				}),
				new TableCell({
					width: {
						size: 10,
						type: WidthType.PERCENTAGE,
					},
					margins: { top: 50, bottom: 50, left: 50 },

					children: [
						new Paragraph({
							children: [
								new TextRun({
									text: validity,
									font: 'Calibri',
									size: 18,
								}),
							],
						}),
					],
				}),
				new TableCell({
					width: {
						size: 20,
						type: WidthType.PERCENTAGE,
					},
					margins: { top: 50, bottom: 50, left: 50 },

					children: [
						new Paragraph({
							children: [
								new TextRun({
									text: training ? new Date(training.datetrained).toLocaleDateString('en-GB', options) : '',
									font: 'Calibri',
									size: 18,
								}),
							],
						}),
					],
				}),
				new TableCell({
					width: {
						size: 20,
						type: WidthType.PERCENTAGE,
					},
					margins: { top: 50, bottom: 50, left: 50 },

					children: [
						new Paragraph({
							children: [
								new TextRun({
									text: training ? new Date(training.expirydate).toLocaleDateString('en-GB', options) : '',
									font: 'Calibri',
									size: 18,
								}),
							],
						}),
					],
				}),
			],
		});
	}

	const tableHeadings = () => {
		return new TableRow({
			children: [
				new TableCell({
					width: {
						size: 50,
						type: WidthType.PERCENTAGE,
					},
					margins: { top: 50, bottom: 50, left: 50 },
					shading: {
						type: ShadingType.SOLID,
						color: 'F4B083',
					},
					children: [
						new Paragraph({
							alignment: AlignmentType.CENTER,
							children: [
								new TextRun({
									text: 'Courses',
									bold: true,
									font: 'Calibri',
									size: 18,
								}),
							],
						}),
					],
				}),
				new TableCell({
					width: {
						size: 10,
						type: WidthType.PERCENTAGE,
					},
					margins: { top: 50, bottom: 50, left: 50 },
					shading: {
						type: ShadingType.SOLID,
						color: 'F4B083',
					},
					children: [
						new Paragraph({
							alignment: AlignmentType.CENTER,
							children: [
								new TextRun({
									text: 'Validity',
									bold: true,
									font: 'Calibri',
									size: 18,
								}),
							],
						}),
					],
				}),
				new TableCell({
					width: {
						size: 20,
						type: WidthType.PERCENTAGE,
					},
					margins: { top: 50, bottom: 50, left: 50 },
					shading: {
						type: ShadingType.SOLID,
						color: 'F4B083',
					},
					children: [
						new Paragraph({
							alignment: AlignmentType.CENTER,
							children: [
								new TextRun({
									text: 'Completion Date',
									bold: true,
									font: 'Calibri',
									size: 18,
								}),
							],
						}),
					],
				}),
				new TableCell({
					width: {
						size: 40,
						type: WidthType.PERCENTAGE,
					},
					margins: { top: 50, bottom: 50, left: 50 },
					shading: {
						type: ShadingType.SOLID,
						color: 'F4B083',
					},
					children: [
						new Paragraph({
							alignment: AlignmentType.CENTER,
							children: [
								new TextRun({
									text: 'Revalidation Date',
									bold: true,
									font: 'Calibri',
									size: 18,
								}),
							],
						}),
					],
				}),
			],
		});
	};

	const imageBase64 = await imageToBase64(staff.image.path) // Image URL
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log(error); // Logs an error if there was one
		});

	const imageLogo = await imageToBase64(logo) // Image URL
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log(error); // Logs an error if there was one
		});

	function textRun(text, bold = false) {
		return new TextRun({
			font: 'Calibri',
			size: 18,
			text: text,
			bold: bold,
		});
	}

	function paragraph(text, bold = false) {
		return new Paragraph({
			children: [textRun(text, bold)],
		});
	}

	const shading = { type: ShadingType.SOLID, color: 'C5E0B3' };

	function createTableCell(text, size, shading) {
		return new TableCell({
			width: {
				size: size ? size : 20,
				type: WidthType.PERCENTAGE,
			},
			margins: shading ? { top: 50, bottom: 50, left: 50 } : {},
			margins: shading ? { top: 50, bottom: 50, left: 50 } : {},
			shading: shading ? shading : {},
			children: [
				new Paragraph({
					children: [
						new TextRun({
							font: 'Calibri',
							text: text,
							size: 20,
							color: '000000',
							bold: shading ? true : false,
						}),
					],
					alignment: AlignmentType.LEFT,
					color: '000000',
				}),
			],
		});
	}

	function createTableCell(text, size, shading) {
		return new TableCell({
			width: {
				size: size ? size : 20,
				type: WidthType.PERCENTAGE,
			},
			margins: shading ? { top: 50, bottom: 50, left: 50 } : {},
			margins: shading ? { top: 50, bottom: 50, left: 50 } : {},
			shading: shading ? shading : {},
			children: [
				new Paragraph({
					children: [
						new TextRun({
							font: 'Calibri',
							text: text,
							size: 20,
							color: '000000',
							bold: shading ? true : false,
						}),
					],
					alignment: AlignmentType.LEFT,
					color: '000000',
				}),
			],
		});
	}

	function createTableCell(text, size, shading) {
		return new TableCell({
			width: {
				size: size ? size : 20,
				type: WidthType.PERCENTAGE,
			},
			margins: shading ? { top: 50, bottom: 50, left: 50 } : {},
			margins: shading ? { top: 50, bottom: 50, left: 50 } : {},
			shading: shading ? shading : {},
			children: [
				new Paragraph({
					children: [
						new TextRun({
							font: 'Calibri',
							text: text,
							size: 20,
							color: '000000',
							bold: shading ? true : false,
						}),
					],
					alignment: AlignmentType.LEFT,
					color: '000000',
				}),
			],
		});
	}

	function createTableCellSmall(text, size, shading) {
		return new TableCell({
			width: {
				size: size ? size : 20,
				type: WidthType.PERCENTAGE,
			},
			margins: shading ? { top: 50, bottom: 50, left: 50 } : {},
			margins: shading ? { top: 50, bottom: 50, left: 50 } : {},
			shading: shading ? shading : {},
			children: [
				new Paragraph({
					children: [
						new TextRun({
							font: 'Calibri',
							text: text,
							size: 14,
							color: '000000',
							bold: shading ? true : false,
						}),
					],
					alignment: AlignmentType.LEFT,
					color: '000000',
				}),
			],
		});
	}

	const profile = new Document({
		sections: [
			{
				properties: {
					type: SectionType.CONTINUOUS,
				},

				headers: {
					default: new Header({
						children: [
							new Paragraph({
								alignment: AlignmentType.CENTER,
								children: [
									new TextRun({
										text: 'Elysium Healthcare Agency Proforma – Temporary Workers',
										font: 'Calibri',
										size: 26,
										bold: true,
									}),
								],
							}),
							new Paragraph({
								alignment: AlignmentType.CENTER,
								children: [
									new TextRun({
										text: 'Neurological Services',
										font: 'Calibri',
										size: 20,
										bold: true,
									}),
								],
							}),
							new Paragraph({
								children: [
									new ImageRun({
										data: Buffer.from(imageLogo, 'base64'),
										transformation: {
											width: 150,
											height: 70,
										},
										floating: {
											horizontalPosition: {
												offset: 2000,
											},
											verticalPosition: {
												offset: 2000,
											},
										},
										wrap: {
											type: TextWrappingType.SQUARE,
											side: TextWrappingSide.BOTH_SIDES,
										},
									}),
								],
							}),
						],
					}),
				},
				children: [
					new Paragraph({
						children: [
							new ImageRun({
								data: Buffer.from(imageBase64, 'base64'),
								transformation: {
									width: 150,
									height: 150,
								},
								floating: {
									horizontalPosition: {
										relative: HorizontalPositionRelativeFrom.COLUMN,
										align: HorizontalPositionAlign.RIGHT,
										offset: -50000,
									},
									verticalPosition: {
										relative: VerticalPositionRelativeFrom.PARAGRAPH,
										align: VerticalPositionAlign.CENTER,
									},
									wrap: {
										type: TextWrappingType.SQUARE,
										side: TextWrappingSide.BOTH_SIDES,
									},
								},
								margins: {
									top: 201440,
									bottom: 201440,
								},
							}),
						],
					}),
					new Paragraph({
						children: [],
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: 'Before you supply an agency worker to Elysium Healthcare, you must complete a proforma for each individual agency worker confirming basic information on the worker before their first shift and recompleted when any information is updated such as training dates etc.',
								font: 'Calibri',
								size: 20,
							}),
						],
					}),
					paragraph(''),
					new Paragraph({
						children: [
							new TextRun({
								text: 'Agency Worker Information',
								bold: true,
								underline: {},
								font: 'Calibri',
								size: 22,
							}),
						],
					}),
					paragraph(''),
					paragraph(''),
					new Table({
						width: {
							size: 75,
							type: WidthType.PERCENTAGE,
						},
						rows: [
							new TableRow({
								children: [createTableCell('Name of Agency', 40, shading), createTableCell(staff.organisationName, 60)],
							}),
						],
					}),
					paragraph(''),
					new Table({
						width: {
							size: 100,
							type: WidthType.PERCENTAGE,
						},
						rows: [
							new TableRow({
								children: [
									createTableCell('Full Forename', 20, shading),
									createTableCell(staff.name, 30),
									createTableCell('Full Surname', 20, shading),
									createTableCell(staff.surname, 30),
								],
							}),
							new TableRow({
								children: [
									createTableCell('Known As', 20, shading),
									createTableCell(staff.name + ' ' + staff.surname, 30),
									createTableCell('Date of Birth', 20, shading),
									createTableCell(
										staff.staff.dateofbirth ? new Date(staff.staff.dateofbirth).toLocaleDateString('en-GB') : '',
										30
									),
								],
							}),
							new TableRow({
								children: [
									createTableCell('NI number', 20, shading),
									createTableCell(staff.staff.ninumber, 30),
									createTableCell('Assignment Role', 20, shading),
									createTableCell(staff.staff.role, 30),
								],
							}),
							new TableRow({
								children: [
									createTableCell('Professional Registration Number*', 30, shading),
									createTableCell(staff.staff.role != 'HCA' ? staff.staff.nmcnumber : '', 20),
									createTableCell('Expiry Date', 20, shading),
									createTableCell(
										staff.staff.nmcexpiry && staff.staff.role != 'HCA'
											? new Date(staff.staff.nmcexpiry).toLocaleDateString('en-GB')
											: '',
										30
									),
								],
							}),
						],
					}),
					//   ADD HERE
					new Paragraph({
						children: [
							new TextRun({
								text: '*NMC/GMC/GTC/HCPC Registration or other relevant professional registration',
								font: 'Calibri',
								size: 16,
							}),
						],
					}),
					paragraph(''),
					new Paragraph({
						children: [
							new TextRun({
								text: 'Agency Worker Compliance Record',
								bold: true,
								underline: {},
								font: 'Calibri',
								size: 20,
							}),
						],
					}),
					paragraph(''),
					new Table({
						width: {
							size: 100,
							type: WidthType.PERCENTAGE,
						},
						rows: [
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 70,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [
											paragraph(
												'Has the agency obtained and retained copies of satisfactory evidence of the workers current Right to Work in the UK, in line with Home Office legislation and is not restricted to the work undertaken? (It is the agency and agency workers responsibility to manage any visa restrictions such as hours etc.)'
											),
										],
									}),
									createTableCell(righttowork ? 'Yes' : 'No'),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 70,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [
											paragraph(
												'Current and valid Certificate of Fitness for the role issued (please insert the name of provider) including any relevant immunisations, required for the role.'
											),
										],
									}),
									createTableCell(''),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 70,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [paragraph('Date Certificate of Fitness was issued')],
									}),
									createTableCell(''),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 70,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [
											paragraph(
												'Has the agency obtained and retained copies of satisfactory references for the last 3 years, a full work history for the agency worker, with all gaps investigated and covered?'
											),
										],
									}),
									createTableCell(references.length >= 2 ? 'Yes' : 'No'),
								],
							}),
						],
					}),
					new Table({
						width: {
							size: 100,
							type: WidthType.PERCENTAGE,
						},
						rows: [
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 60,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [
											paragraph('Disclosure and Barring Service (DBS) certificate number (12 digits, no letters)'),
										],
									}),
									createTableCell(staff.staff.dbsnumber),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 60,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [paragraph('Disclosure and Barring Service Issue Date')],
									}),
									createTableCell(
										staff.staff.dbsissue ? new Date(staff.staff.dbsissue).toLocaleDateString('en-GB') : ''
									),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 60,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [
											paragraph(
												'If on the DBS Update Service, please confirm the date the most recent Update Check was completed'
											),
										],
									}),
									createTableCell(''),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 60,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [paragraph('Level of DBS Certificate Issued (Basic, Standard, Enhanced)')],
									}),
									createTableCell(staff.staff.dbsnumber ? staff.staff.dbslevel : ''),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 60,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [paragraph('Has this been checked against the Adult and/or Child Barred List?')],
									}),
									createTableCell(staff.staff.dbsnumber ? 'Adult and Childrens' : ''),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 60,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [
											paragraph('Has an overseas police check been obtained in line with Elysium Agency Framework?'),
										],
									}),
									createTableCell('Yes'),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 60,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [
											paragraph(
												'If the certificate contains any criminal history, has this been suitably assessed to ensure the worker is suitable for placement within Elysium?'
											),
										],
									}),
									createTableCell('N/A'),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 60,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [
											paragraph(
												'Is there a complete work history and references in line with Elysium Agency Framework on record for the above named?'
											),
										],
									}),
									createTableCell('Yes'),
								],
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 60,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'F2F2F2',
										},
										children: [
											paragraph(
												'Has the Qualification and/or Professional Registration been completed in line with Elysium Agency Framework, where required for role?'
											),
										],
									}),
									createTableCell('Yes'),
								],
							}),
						],
					}),
					paragraph(''),
					new Paragraph({
						children: [
							new TextRun({
								text: 'Agency Worker Training Record',
								bold: true,
								underline: {},
								font: 'Calibri',
								size: 20,
							}),
						],
					}),
					paragraph(),
					new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: 'Core Statutory Courses',
								bold: true,

								font: 'Calibri',
								size: 20,
							}),
						],
					}),
					paragraph(''),
					new Table({
						width: {
							size: 100,
							type: WidthType.PERCENTAGE,
						},
						rows: [
							tableHeadings(),
							//   TRAINING ROWS
							trainingRow('Basic life support**', '1 Year', basicLifeSupport),
							trainingRow('Immediate Life Support (ILS) (qualified staff only)**', '1 Year', immediateLifeSupport),
							trainingRow('Manual Handling of Objects', '2 Years', manualHandling),
							trainingRow('Conflict Resolution & Breakaway', '1 Year', breakAway),
							trainingRow('Infection Prevention & Control', '1 Year', infectionControl),
							trainingRow('Safeguarding Adults - Level 1 & 2', '3 Years', safeguard),
							trainingRow('Safeguarding Children - Level 1 & 2', '3 Years', safeguard),
							trainingRow('Mental Capacity Act & Deprivation of Liberty Safeguards (DoLS)', '1 Year', mentalAct),
							trainingRow('Information Governance & GDPR', '1 Year', informationGovernance),
							trainingRow('NEWS2 ', '1 Year', news),
							trainingRow('PREVENT/WRAP', '3 Years', prevent),
							trainingRow('Food Safety - Level 1', '3 Years', foodHygiene),
							trainingRow('Equality, Diversity & Human Rights', '3 Years', equality),
							trainingRow('Health & Safety (Incl. COSHH Awareness)', '2 Year', healthSafety),
							trainingRow('Fire Safety', '2 Years', fire),
							trainingRow('Safe Handling of Medication – Level 2 (qualified staff only)', '1 Years', medicalManagement),
							trainingRow('Oliver McGowan Training', '1 Years', oliver),
						],
					}),
					paragraph(''),
					new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: 'Additional Courses required for Secure Services and Non-Secure Services',
								bold: true,

								font: 'Calibri',
								size: 20,
							}),
						],
					}),
					paragraph(''),
					new Table({
						width: {
							size: 100,
							type: WidthType.PERCENTAGE,
						},
						rows: [
							tableHeadings(),
							//   TRAINING ROWS
							//TODO: FILL
							trainingRow('Safe Administration of Medicines Level 1 (Handling)', '1 Year', medicalManagement),
							trainingRow('Physical Observations', '1 Year', observations),
							trainingRow('Moving & Handling People', '1 Year', moving),
							trainingRow('Dysphagia and IDDSI', '', dysphagia),
							trainingRow('Behaviour', '', behaviour),
							trainingRow('Enhanced Observations', '', observations),
							trainingRow('PEG management', '', null),
							trainingRow('Tracheostomy Care', '', null),
							trainingRow('Diabetes and Blood Glucose Monitoring', '', observations),
							trainingRow('Seizure and Epilepsy Awareness', '', observations),
						],
					}),
					paragraph(''),

					new Paragraph({
						children: [
							new TextRun({
								text: '** Courses must be undertaken face-to-face and practical training involved',

								font: 'Calibri',
								size: 16,
							}),
						],
					}),
					new Paragraph({
						children: [
							new TextRun({
								text: '1. Registered as BILD accredited or working towards accreditation, listed on the BILD website',

								font: 'Calibri',
								size: 16,
							}),
						],
					}),
					paragraph(''),
					new Paragraph({
						children: [
							new TextRun({
								text: 'Further Information',
								bold: true,
								font: 'Calibri',
								size: 18,
								underline: {},
							}),
						],
					}),
					paragraph(''),
					new Paragraph({
						children: [
							new TextRun({
								text: `Please confirm that the agency worker has a valid photo ID issued by the agency with a photo that matches the photo attached:${
									companyId ? 'Yes' : 'No'
								}`,

								font: 'Calibri',
								size: 18,
							}),
						],
					}),
					paragraph(''),
					new Paragraph({
						children: [
							new TextRun({
								text: 'confirmation',
								bold: true,
								font: 'Calibri',
								size: 18,
								underline: {},
							}),
						],
					}),
					paragraph(''),
					new Paragraph({
						children: [
							new TextRun({
								text: `The agency worker named in this proforma has been supplied by the agency named on this proforma, to Elysium Healthcare (Elysium) for consideration to undertake temporary assignments with Elysium.`,

								font: 'Calibri',
								size: 18,
							}),
						],
					}),
					paragraph(''),
					new Paragraph({
						children: [
							new TextRun({
								text: `I confirm that by submitting this proforma that the information is correct and factual. I also understand that this proforma will also be used during any audit of the agency worker files to ensure compliance with CQC and Elysium requirements as legally and contractually required.`,

								font: 'Calibri',
								size: 18,
							}),
						],
					}),
					paragraph(''),
					new Paragraph({
						children: [
							new TextRun({
								text: `I also confirm that all information and documentation on this agency worker will be maintained and up to date and a new proforma will be provided to Elysium such as when Right to Work/DBS or Training documents are updated.`,

								font: 'Calibri',
								size: 18,
							}),
						],
					}),
					paragraph(''),
					new Table({
						width: {
							size: 75,
							type: WidthType.PERCENTAGE,
						},
						rows: [
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: 25,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'BDD6EE',
										},
										children: [
											new Paragraph({
												alignment: AlignmentType.CENTER,
												children: [
													new TextRun({
														text: 'Name',

														font: 'Calibri',
														size: 18,
													}),
												],
											}),
										],
									}),
									new TableCell({
										width: {
											size: 25,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'BDD6EE',
										},
										children: [
											new Paragraph({
												alignment: AlignmentType.CENTER,
												children: [
													new TextRun({
														text: 'Date',

														font: 'Calibri',
														size: 18,
													}),
												],
											}),
										],
									}),
								],
							}),
							new TableRow({
								children: [
									createTableCell(user.user.name + ' ' + user.user.surname ?? '', 60),
									createTableCell(new Date().toLocaleDateString('en-GB'), 40),
								],
							}),
						],
					}),
					paragraph(''),
					paragraph(
						'Once completed please email this proforma to the relevant contact within Elysium Healthcare prior to the agency worker starting.'
					),
					paragraph(''),
					new Paragraph({
						children: [
							new TextRun({
								text: `Data Protection Statement`,
								bold: true,
								font: 'Calibri',
								size: 18,
								underline: {},
							}),
						],
					}),
					paragraph(''),
					paragraph(
						'All information will used in for the purposes of engagement and supply of agency workers and activities in relation to the engagement and supply of agency workers. All information will be treated as confidential and managed in accordance with the relevant data protect legislation and guidance.'
					),
					paragraph(''),
					paragraph(''),
					new Table({
						alignment: AlignmentType.END,
						width: {
							size: 50,
							type: WidthType.PERCENTAGE,
						},
						rows: [
							new TableRow({
								children: [
									new TableCell({
										columnSpan: 2,
										width: {
											size: 100,
											type: WidthType.PERCENTAGE,
										},
										margins: { top: 50, bottom: 50, left: 50 },
										shading: {
											type: ShadingType.SOLID,
											color: 'BDD6EE',
										},
										children: [
											new Paragraph({
												children: [
													new TextRun({
														text: 'Document Control',
														font: 'Calibri',
														size: 14,
													}),
												],
											}),
										],
									}),
								],
							}),
							new TableRow({
								children: [createTableCellSmall('version:', 40), createTableCellSmall('3', 60)],
							}),
							new TableRow({
								children: [createTableCellSmall('Effective Date:', 40), createTableCellSmall('23.01.2025', 60)],
							}),
							new TableRow({
								children: [
									createTableCellSmall('Author:', 40),
									createTableCellSmall('Shaun Cox - HR Compliance Manager', 60),
								],
							}),
							new TableRow({
								children: [createTableCellSmall('Editor:', 40), createTableCellSmall('', 60)],
							}),
							new TableRow({
								children: [
									createTableCellSmall('Approved By:', 40),
									createTableCellSmall('Shaun Cox - HR Compliance Manager', 60),
								],
							}),
							new TableRow({
								children: [createTableCellSmall('Date of Next Review:', 40), createTableCellSmall('January 2026', 60)],
							}),
						],
					}),
				],
				footers: {
					default: new Footer({
						children: [
							new Paragraph({
								alignment: AlignmentType.END,
								children: [
									new TextRun({
										text: 'Elysium Healthcare Agency Proforma – Neurological Services - Version 3',
										font: 'Calibri',
										size: 20,
										bold: true,
									}),
								],
							}),
						],
					}),
				},
			},
		],
	});

	Packer.toBlob(profile)
		.then((blob) => {
			saveAs(blob, staff.name + '_' + staff.surname + '_Elysium_NS_profile');
		})
		.catch((error) => {
			console.log(error);
		});
};

export default elysiumNS;
