import React, { useEffect, useState } from "react";
import { getProfile, reset } from "../../features/Profile/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import { FaTools } from "react-icons/fa";
import AssignedStaff from "./AssignedStaff";
import StaffMadeCompliant from "./StaffMadeCompliant";
import Settings from "./Settings";
import { useNavigate } from "react-router-dom";
import complianceService from "../../features/Compliance/complianceService";

function SingleAdmin({ id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  const user = JSON.parse(localStorage.getItem("user"));

  const [page, setPage] = useState("assigned");

  const admin = profile ? profile.user : null;

  const assignToTeam = async (e) => {
    //get value from select
    const teamId = e.target.value;

    const team = await complianceService.assignUserToGroup(id, teamId);
  };

  useEffect(() => {
    // GET ADMIN PROFILE
    dispatch(getProfile(id));

    return () => {
      reset();
    };
  }, []);

  if (!admin) {
    return <Spinner />;
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold">
        {admin.name} {admin.surname}
      </h2>
      <h4 className="font-semibold">{admin.email}</h4>
      <p>last signed in:</p>
      <p>{new Date(admin.lastLoggedIn).toLocaleDateString("en-GB")}</p>
      <div className="w-1/5">
        <p className="mb-1">Currently in team : {admin.team}</p>
        <select
          id="team"
          class="form-select"
          name="team"
          onChange={assignToTeam}
          className="w-full"
          required
        >
          <option value="">Select a team</option>
          <option value="A">Team A</option>
          <option value="B">Team B</option>
          <option value="C">Team C</option>
          <option value="D">Team D</option>
        </select>
      </div>
      <div className=" my-3 flex justify-center">
        <div
          className="cursor-pointer p-2 border border-dark rounded-sm mx-2 shadow-sm"
          onClick={() => {
            window.open(`/profile/${admin._id}`, "_blank");
          }}
        >
          Profile
        </div>
        <div
          className="cursor-pointer p-2 border border-dark rounded-sm mx-2 shadow-sm"
          onClick={() => {
            setPage("assigned");
          }}
        >
          Assigned{" "}
          <span className="bg-dark text-white p-1 m-1 rounded-full text-xs">
            {admin.assignedTo.length}
          </span>
        </div>
        <div
          className="cursor-pointer p-2 border border-dark rounded-sm mx-2 shadow-sm"
          onClick={() => {
            setPage("compliant");
          }}
        >
          Made Compliant{" "}
          <span className="bg-dark text-white p-1 m-1 rounded-full text-xs">
            {admin.staffMadeCompliant.length}
          </span>
        </div>

        <div
          className=" cursor-pointer p-2 border border-dark rounded-sm mx-2 shadow-sm flex align-items-center"
          onClick={() => {
            setPage("settings");
          }}
        >
          Settings{" "}
          <span className="ml-2">
            <FaTools />
          </span>
        </div>
      </div>
      <hr className=" border-2 border-dark mb-3" />
      {page === "assigned" && <AssignedStaff staff={admin.assignedTo} />}
      {page === "compliant" && (
        <StaffMadeCompliant staff={admin.staffMadeCompliant} />
      )}
      {page === "settings" && <Settings admin={admin} />}
    </div>
  );
}

export default SingleAdmin;